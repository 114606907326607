.footer {
  padding: 3em 0;
  margin-top: 4em;
  text-align: center;
}

.footer__link {
  font-size: 0.9rem;
  font-weight: 600;
  background: linear-gradient(to right, 
  #bf71c1, #c6a0f6, #8aadf4, #a6da95, #eed49f, #f5a97f, #ed8796);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

@media (max-width: 600px) {
  .footer {
    padding: 2em;
    margin-top: 3em;
  }
}


